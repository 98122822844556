<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar
      title="夜宵"
      left-arrow
      @click-left="$router.go(-1)"
      border
    />
    <!-- /导航栏 -->

    <!-- 商品排序栏 -->
    <div class="cateSort">
      <!-- 综合排序 -->
      <div @click="bySynthesize" :class="['cateSort-item', 'filter', {
        active: order.by === 'synthesize'
      }]">综合</div>
      <!-- /综合排序 -->
      <!-- 销量排序 -->
      <div @click="bySell" :class="['cateSort-item', 'cateSale', 'filter', {
        active: order.by === 'sell'
      }]">
        <div class="contentBox">
          <div>销量</div>
          <div class="imgBox">
            <!-- 销量升序 -->
            <img :class="{
              bgc: order.by === 'sell' && order.upDown === 1
            }" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd3ac28823f857cd083e2af0d02cb0ae8c7d89db59fafb7e2c1cfb30ddb0c4674" alt="">
            <!-- 销量升序 -->
            <img :class="{
              bgc: order.by === 'sell' && order.upDown === 0
            }" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1c441d780f9eba6e8107e549d9a267d1ef5beb4b22788741f5f73d9a14a4566c" alt="">
          </div>
        </div>
      </div>
      <!-- /销量排序 -->
      <!-- 距离排序 -->
      <div @click="byDistance" :class="['cateSort-item', 'distance', 'filter', {
        active: order.by === 'distance'
      }]">
        <div class="contentBox">
          <div>距离</div>
          <div class="imgBox">
            <img :class="{
              bgc: order.by === 'distance' && order.upDown === 1
            }" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd3ac28823f857cd083e2af0d02cb0ae8c7d89db59fafb7e2c1cfb30ddb0c4674" alt="">
            <img :class="{
              bgc: order.by === 'distance' && order.upDown === 0
            }" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1c441d780f9eba6e8107e549d9a267d1ef5beb4b22788741f5f73d9a14a4566c" alt="">
          </div>
        </div>
      </div>
      <!-- /距离排序 -->
    </div>
    <!-- /商品排序栏 -->
    <!-- 列表 -->
    <div class="list-two-box">
      <div class="list-two">
      <div class="list-two-item">
        <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbc32dba9a95f41afd94bd59bc8f466d91fc0c354bcad529450bac89091061731" alt="">
        <div class="list-two-right">
          <div class="title">
            <span class="title-span-left">烤鱼先生·香辣烤...</span>
            <span class="title-span-right">0.5km</span>
          </div>
          <div class="discount">
            <div>
              <span>20减20</span>
            </div>
            <div>
              <span>20减20</span>
            </div>
            <div class="span-right">
              <span>20减20</span>
            </div>
          </div>
          <div class="banner">
            <div class="banner-item">
              <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga526d643b73b38df0d58d50f4aa317b8599f05c88259e6ed890c1dcaa418ef47" alt="">
              <p>糖醋里脊...</p>
              <div class="banner-bottom">
                <span class="banner-a">￥10.9</span>
                <span class="banner-b">16.9</span>
              </div>
            </div>
            <div class="banner-item">
              <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga526d643b73b38df0d58d50f4aa317b8599f05c88259e6ed890c1dcaa418ef47" alt="">
              <p>糖醋里脊...</p>
              <div class="banner-bottom">
                <span class="banner-a">￥10.9</span>
                <span class="banner-b">16.9</span>
              </div>
            </div>
            <div class="banner-item">
              <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga526d643b73b38df0d58d50f4aa317b8599f05c88259e6ed890c1dcaa418ef47" alt="">
              <p>糖醋里脊...</p>
              <div class="banner-bottom">
                <span class="banner-a">￥10.9</span>
                <span class="banner-b">16.9</span>
              </div>
            </div>
          </div>
         </div>
       </div>
    </div> 
    </div>
    <!-- /列表 -->
  </div>
</template>

<script>
  export default {
    data () {
      return {
        order: {
          by: 'synthesize', // synthesize 综合排序 sell 销量排序 distance 距离排序
          upDown: 0 // 1升序 2 降序 默认降序
        }
      }
    },
    methods: {
      bySynthesize () {
        if (this.order.by === 'sell' || this.order.by === 'distance'){
          this.order.by = 'synthesize'
        }
      },
      // 销量排序
      bySell () {
        if (this.order.by === 'synthesize' || this.order.by === 'distance') {
          this.order.by = 'sell'
          this.order.upDown = 0
        } else {
          if (this.order.upDown === 1) {
            this.order.upDown = 0
          } else {
            this.order.upDown = 1
          }
        }
      },
      // 距离排序
      byDistance () {
        if (this.order.by === 'synthesize' || this.order.by === 'sell') {
          this.order.by = 'distance'
          this.order.upDown = 1
        } else {
          if (this.order.upDown === 1) {
             this.order.upDown = 0
          } else {
            this.order.upDown = 1
          }
        }
      },
    },
    created () {
    },
   
  }
</script>

<style scoped>
.cateSort {
  height: 1rem;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background: white;
  display: flex;
  justify-content: space-around;
  font-size: 0.3243rem;
  color: #999;
}
.cateSort .cateSort-item {
  width: 2.5676rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentBox {
  display: flex;
  width: 1.0541rem;
  height: 0.4595rem;
}
.imgBox {
  display: flex;
  flex-direction: column;
  margin-left: 0.1622rem;
  justify-content: space-between;
}
.active {
  color: #333;
  font-weight: bold;
}
.bgc {
  background: #333;
  border-radius: 0.1351rem;
}
.list-two-box {
  display: flex;
  justify-content: center;
}
.list-two {
  width: 9.5946rem;
  margin-top: 0.2703rem;
  display: flex;
  justify-content: center;
  background: white;
}
.list-two-item {
  margin-top: 0.2703rem;
  width: 9.5946rem;
  display: flex;
  border-radius: 0.1351rem;
  padding: 0 0.2703rem 0 0.2703rem;
}
.list-two-item img {
  height: 3.973rem;
}
.list-two-right {
  flex: 1;
  padding: 0 0.2703rem 0 0.2432rem;
}
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.0811rem;
}
.title-span-left {
  font-weight: bold;
  font-size: 0.4324rem;
}
.title-span-right {
  font-size: 0.3784rem;
  color: #999;
}
.discount {
  width: 3.7838rem;
  height: 0.4054rem;
  border: 0.027rem solid #FF5200;
  display: flex;
  font-size: 0.3243rem;
  display: flex;
  align-items: center;
  color: #FF5200;
  border-radius: 0.0541rem;
}
.discount div {
  height: 0.3243rem;
  border-right: 0.027rem solid #FF5200;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.0811rem;
  padding-right: 0.1081rem;
}
.discount .span-right {
  border: 0;
}
.banner {
  width: 6.1351rem;
  margin-top: 0.0811rem;
  display: flex;
  overflow: auto;
}
.banner::-webkit-scrollbar { display: none; }
.banner p {
  margin-top: 0.0811rem;
}
.banner img {
  width: 76px;
}
.banner-item {
  width: 2.0541rem;
  height: 2.7838rem;
  font-size: 0.3243rem;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.2432rem;
}
.banner-bottom {
  display: flex;
  margin-top: 0.0811rem;
}
.banner-bottom .banner-a {
  font-weight: bold;
  color: red;
}
.banner-bottom .banner-b {
  margin-left: 0.0811rem;
  text-decoration: line-through;
}
</style>